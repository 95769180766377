import { Channel } from '~/gql/graphql';

// 新しい診断結果の対象が増えたらここに足す
const newDiagnosisResultTargetChannels = [
  Channel.Okaneko,
  Channel.Rakuten,
  Channel.Yahoo,
  Channel.Sbi,
  Channel.FourHundredF,
  Channel.Ninpath,
];

export const useNewDiagnosisResultTarget = (
  viewerChannelRef: RefOrGetterFunction<Nullish<Channel>>
) => {
  const isNewDiagnosisResultTarget = computed(() =>
    newDiagnosisResultTargetChannels.some(
      (value) => value === toValue(viewerChannelRef)
    )
  );

  return { isNewDiagnosisResultTarget };
};
